// 日本語化
// メッセージ(英字): メッセージ(日本語)
export const i18n = {
    ja: {
        // Cognitoエラー
        // サインイン画面
        'The username should either be a string or one of the sign in types':
            'メールアドレスを入力してください。',
        'Custom auth lambda trigger is not configured for the user pool.':
            'パスワードを入力してください。',
        'Incorrect username or password.':
            'メールアドレスまたはパスワードが違います',

        // サインアップ画面
        "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256":
            'パスワードの長さは 256文字以下である必要があります',
        'Username should be an email.':
            'メールアドレスの形式で入力してください。',

        // バックエンドエラーメッセージ
        'An account with the given email already exists.':
            'このアカウントはすでに存在します。\nログインメニューからログインして利用するか、10分後に改めて新規登録してください。',
        'Exceeded daily email limit for the operation or the account. If a higher limit is required, please configure your user pool to use your own Amazon SES configuration for sending email.':
            'アカウントの 1 日あたりのメール制限を超えました。', // １日のメール送信上限回数を超えると発生。AWS SESの設定を見直す必要あり。インフラ運用で設定を変更するため、発生しなくなるはず。

        // 認証コード入力画面
        'Invalid verification code provided, please try again.':
            '無効なコードです。正しいコードを入力してください。',
        'Cannot reset password for the user as there is no registered/verified email or phone_number':
            '登録/確認された電子メールがないため、アカウントのパスワードを再設定できません',

        // 画面共通
        'Username cannot be empty': 'メールアドレスを入力してください。', // サインイン・サインアップ・パスワード再発行画面で発生
        'User does not exist.':
            'このアカウントは存在しません。\nメールアドレスが正しくかなったか、アカウント登録が10分以内に完了しなかった可能性があります。', // サインイン画面で発生
        'Username/client id combination not found.':
            'このアカウントは存在しません。\nメールアドレスが正しくかなったか、アカウント登録が10分以内に完了しなかった可能性があります。', // パスワード再発行画面&検証コード入力画面で発生
        'Attempt limit exceeded, please try after some time.':
            '試行制限を超えました。しばらくしてからお試しください。', // パスワード変更&パスワード再発行画面で発生
        "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128":
            'メールアドレスは128文字以下で入力してください。', //サインアップ・パスワード再発行画面で発生

        // MFA認証画面MFA
        'Invalid code or auth state for the user.':
            '無効なコードです。正しいコードを入力してください。',

        // その他
        'Invalid email address format.':
            'メールアドレスの形式で入力してください。',

        'User is not confirmed.': 'アカウントは検証されていません',
        'User already exists': 'アカウントは既に存在します',
        'Invalid phone number format': '不正な電話番号フォーマットです。 ',
        'Password attempts exceeded': 'パスワード試行回数が超過しました',
        'Invalid phone number format.': '携帯電話番号の形式が正しくありません',
        'Invalid session for the user, session is expired.':
            'セッションの有効期限が切れました。\n認証コードは3分以内に入力する必要があります。\nコードを再送信して入力してください。',
        'User is disabled.': 'アカウントは無効になっています。', // アカウントをcognitoコンソール上で無効
        'User is already confirmed.': 'アカウントは既に存在します', // セキュリティコード承認後のアカウントに対してセキュリティコード送信をすると発生
        'Incorrect current password.': '現在のパスワードが正しくありません。', // 独自error文言。useChangePassword hooks内で'Incorrect username or password.'を変換
        'not authenticated': 'アカウントが認証されていません', // 未認証時のcurrentAuthenticatedUser実行で発生
        'User cannot be confirmed. Current status is CONFIRMED':
            '現在認証済みなのでアカウントの確認は必要ありません', // 認証済みのアカウントでセキュリティコード入力時に発生
        'Internal server error.': 'サーバーエラーが発生しました。',
        'User password cannot be reset in the current state.':
            '初回ログインが完了していないため、アカウントのパスワードをリセットすることはできません', // cognitoコンソールからアカウント発行後、初回ログイン時のパスワード設定を行っていない状態でforgotPassword API(パスワード忘れAPI)を実行すると発生
        'Invalid session for the user, session can only be used once.':
            'セッションが無効です。セッションは１回しか使用できません。', // cognitoコンソールからアカウント発行後、初回ログイン時の新パスワード設定を連続で複数回行うと発生

        // バックエンドエラーメッセージ
        // Stripeエラー
        // 拒否された支払い(https://stripe.com/docs/testing#%E6%8B%92%E5%90%A6%E3%81%95%E3%82%8C%E3%81%9F%E6%94%AF%E6%89%95%E3%81%84)
        // 拒否コード
        // カード登録の場合
        generic_decline:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        insufficient_funds:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        lost_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        stolen_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        // エラーコード
        expired_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        incorrect_cvc:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        processing_error:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        incorrect_number:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        payment_intent_authentication_failure:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        card_number_in_name_field:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        live_mode_test_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        // カード情報変更の場合
        mp_generic_decline:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_insufficient_funds:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_lost_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_stolen_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        // エラーコード
        mp_expired_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_incorrect_cvc:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_processing_error:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_incorrect_number:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_live_mode_test_card:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',

        // その他
        'double error':
            'その定義名はすでに存在します。\n他の定義名で保存してください。',
        setup_error:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
        mp_setup_error:
            'クレジットカードの登録に失敗しました。\n正しいカード情報を入力して再度お試しいただくか、他のクレジットカードにてお支払いください。',
    },
};
